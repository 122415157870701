if (typeof Util === "undefined") {
  var Util = function () {};
}

Util.hasClass = function (el, className) {
  return el.classList.contains(className);
};

Util.addClass = function (el, className) {
  var classList = className.split(" ");
  el.classList.add(classList[0]);
  if (classList.length > 1) Util.addClass(el, classList.slice(1).join(" "));
};

Util.removeClass = function (el, className) {
  var classList = className.split(" ");
  el.classList.remove(classList[0]);
  if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(" "));
};

Util.toggleClass = function (el, className, bool) {
  if (bool) Util.addClass(el, className);
  else Util.removeClass(el, className);
};

Util.setAttributes = function (el, attrs) {
  for (var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
};

const moveFocus = (element) => {
  if (!element) element = document.getElementsByTagName("body")[0];
  element.focus();
  if (document.activeElement !== element) {
    element.setAttribute("tabindex", "-1");
    element.focus();
  }
};

Util.getIndexInArray = function (array, el) {
  return Array.prototype.indexOf.call(array, el);
};

// File#: _1_menu
// Usage: codyhouse.co/license
(function () {
  var Menu = function (element) {
    this.element = element;
    this.elementId = this.element.getAttribute("id");
    this.menuItems = this.element.getElementsByClassName("js-menu__content");
    this.trigger = document.querySelectorAll(
      '[aria-controls="' + this.elementId + '"]',
    );
    this.selectedTrigger = false;
    this.menuIsOpen = false;
    this.initMenu();
    this.initMenuEvents();
  };

  Menu.prototype.initMenu = function () {
    // init aria-labels
    for (var i = 0; i < this.trigger.length; i++) {
      this.trigger[i].setAttribute("aria-expanded", "false");
      this.trigger[i].setAttribute("aria-haspopup", "true");
    }
    // init tabindex
    for (var i = 0; i < this.menuItems.length; i++) {
      this.menuItems[i].setAttribute("tabindex", "0");
    }
  };

  Menu.prototype.initMenuEvents = function () {
    var self = this;
    for (var i = 0; i < this.trigger.length; i++) {
      (function (i) {
        self.trigger[i].addEventListener("click", function (event) {
          event.preventDefault();
          // if the menu had been previously opened by another trigger element -> close it first and reopen in the right position
          if (
            self.element.classList.contains("menu--is-visible") &&
            self.selectedTrigger != self.trigger[i]
          ) {
            self.toggleMenu(false, false); // close menu
          }
          // toggle menu
          self.selectedTrigger = self.trigger[i];
          self.toggleMenu(
            !self.element.classList.contains("menu--is-visible"),
            true,
          );
        });
      })(i);
    }

    // keyboard events
    this.element.addEventListener("keydown", function (event) {
      // use up/down arrow to navigate list of menu items
      if (!event.target.classList.contains("js-menu__content")) return;
      if (
        (event.keyCode && event.keyCode == 40) ||
        (event.key && event.key.toLowerCase() == "arrowdown")
      ) {
        self.navigateItems(event, "next");
      } else if (
        (event.keyCode && event.keyCode == 38) ||
        (event.key && event.key.toLowerCase() == "arrowup")
      ) {
        self.navigateItems(event, "prev");
      }
    });
  };

  Menu.prototype.toggleMenu = function (bool, moveFocus) {
    var self = this;
    // toggle menu visibility
    this.element.classList.toggle("menu--is-visible", bool);
    this.menuIsOpen = bool;
    if (bool) {
      this.selectedTrigger.setAttribute("aria-expanded", "true");
      moveFocusFn(this.menuItems[0]);
      this.element.addEventListener(
        "transitionend",
        function (event) {
          moveFocusFn(self.menuItems[0]);
        },
        { once: true },
      );
      // position the menu element
      this.positionMenu();
      // add class to menu trigger
      this.selectedTrigger.classList.add("menu-control--active");
    } else if (this.selectedTrigger) {
      this.selectedTrigger.setAttribute("aria-expanded", "false");
      if (moveFocus) moveFocusFn(this.selectedTrigger);
      // remove class from menu trigger
      this.selectedTrigger.classList.remove("menu-control--active");
      this.selectedTrigger = false;
    }
  };

  Menu.prototype.positionMenu = function (event, direction) {
    var selectedTriggerPosition = this.selectedTrigger.getBoundingClientRect(),
      menuOnTop =
        window.innerHeight - selectedTriggerPosition.bottom <
        selectedTriggerPosition.top;
    // menuOnTop = window.innerHeight < selectedTriggerPosition.bottom + this.element.offsetHeight;

    var left = selectedTriggerPosition.left,
      right = window.innerWidth - selectedTriggerPosition.right,
      isRight =
        window.innerWidth <
        selectedTriggerPosition.left + this.element.offsetWidth;

    var horizontal = isRight
        ? "right: " + right + "px;"
        : "left: " + left + "px;",
      vertical = menuOnTop
        ? "bottom: " +
          (window.innerHeight - selectedTriggerPosition.top) +
          "px;"
        : "top: " + selectedTriggerPosition.bottom + "px;";
    // check right position is correct -> otherwise set left to 0
    if (isRight && right + this.element.offsetWidth > window.innerWidth)
      horizontal =
        "left: " +
        parseInt((window.innerWidth - this.element.offsetWidth) / 2) +
        "px;";
    var maxHeight = menuOnTop
      ? selectedTriggerPosition.top - 20
      : window.innerHeight - selectedTriggerPosition.bottom - 20;
    this.element.setAttribute(
      "style",
      horizontal + vertical + "max-height:" + Math.floor(maxHeight) + "px;",
    );
  };

  Menu.prototype.navigateItems = function (event, direction) {
    event.preventDefault();
    var index = Array.prototype.indexOf.call(this.menuItems, event.target),
      nextIndex = direction == "next" ? index + 1 : index - 1;
    if (nextIndex < 0) nextIndex = this.menuItems.length - 1;
    if (nextIndex > this.menuItems.length - 1) nextIndex = 0;
    moveFocusFn(this.menuItems[nextIndex]);
  };

  Menu.prototype.checkMenuFocus = function () {
    var menuParent = document.activeElement.closest(".js-menu");
    if (!menuParent || !this.element.contains(menuParent))
      this.toggleMenu(false, false);
  };

  Menu.prototype.checkMenuClick = function (target) {
    if (
      !this.element.contains(target) &&
      !target.closest('[aria-controls="' + this.elementId + '"]')
    )
      this.toggleMenu(false);
  };

  function moveFocusFn(element) {
    element.focus();
    if (document.activeElement !== element) {
      element.setAttribute("tabindex", "-1");
      element.focus();
    }
  }

  window.Menu = Menu;

  //initialize the Menu objects
  var menus = document.getElementsByClassName("js-menu");
  if (menus.length > 0) {
    var menusArray = [];
    var scrollingContainers = [];
    for (var i = 0; i < menus.length; i++) {
      (function (i) {
        menusArray.push(new Menu(menus[i]));
        var scrollableElement = menus[i].getAttribute(
          "data-scrollable-element",
        );
        if (
          scrollableElement &&
          !scrollingContainers.includes(scrollableElement)
        )
          scrollingContainers.push(scrollableElement);
      })(i);
    }

    // listen for key events
    window.addEventListener("keyup", function (event) {
      if (
        (event.keyCode && event.keyCode == 9) ||
        (event.key && event.key.toLowerCase() == "tab")
      ) {
        //close menu if focus is outside menu element
        menusArray.forEach(function (element) {
          element.checkMenuFocus();
        });
      } else if (
        (event.keyCode && event.keyCode == 27) ||
        (event.key && event.key.toLowerCase() == "escape")
      ) {
        // close menu on 'Esc'
        menusArray.forEach(function (element) {
          element.toggleMenu(false, false);
        });
      }
    });
    // close menu when clicking outside it
    window.addEventListener("click", function (event) {
      menusArray.forEach(function (element) {
        element.checkMenuClick(event.target);
      });
    });
    // on resize -> close all menu elements
    window.addEventListener("resize", function (event) {
      menusArray.forEach(function (element) {
        element.toggleMenu(false, false);
      });
    });
    // on scroll -> close all menu elements
    window.addEventListener("scroll", function (event) {
      menusArray.forEach(function (element) {
        if (element.menuIsOpen) element.toggleMenu(false, false);
      });
    });
    // take into account additinal scrollable containers
    for (var j = 0; j < scrollingContainers.length; j++) {
      var scrollingContainer = document.querySelector(scrollingContainers[j]);
      if (scrollingContainer) {
        scrollingContainer.addEventListener("scroll", function (event) {
          menusArray.forEach(function (element) {
            if (element.menuIsOpen) element.toggleMenu(false, false);
          });
        });
      }
    }
  }
})();
