// File#: _2_flexi-header
// Usage: codyhouse.co/license
(function () {
  var flexHeader = document.getElementsByClassName("js-f-header");
  if (flexHeader.length > 0) {
    var menuTrigger =
        flexHeader[0].getElementsByClassName("js-anim-menu-btn")[0],
      firstFocusableElement = getMenuFirstFocusable();

    // we'll use these to store the node that needs to receive focus when the mobile menu is closed
    var focusMenu = false;

    resetFlexHeaderOffset();
    setAriaButtons();

    menuTrigger.addEventListener("anim-menu-btn-clicked", function (event) {
      toggleMenuNavigation(event.detail);
    });

    // listen for key events
    window.addEventListener("keyup", function (event) {
      // listen for esc key
      if (
        (event.keyCode && event.keyCode == 27) ||
        (event.key && event.key.toLowerCase() == "escape")
      ) {
        // close navigation on mobile if open
        if (
          menuTrigger.getAttribute("aria-expanded") == "true" &&
          isVisible(menuTrigger)
        ) {
          focusMenu = menuTrigger; // move focus to menu trigger when menu is close
          menuTrigger.click();
        }
      }
      // listen for tab key
      if (
        (event.keyCode && event.keyCode == 9) ||
        (event.key && event.key.toLowerCase() == "tab")
      ) {
        // close navigation on mobile if open when nav loses focus
        if (
          menuTrigger.getAttribute("aria-expanded") == "true" &&
          isVisible(menuTrigger) &&
          !document.activeElement.closest(".js-f-header")
        )
          menuTrigger.click();
      }
    });

    // detect click on a dropdown control button - expand-on-mobile only
    flexHeader[0].addEventListener("click", function (event) {
      var btnLink = event.target.closest(".js-f-header__dropdown-control");
      if (!btnLink) return;
      !btnLink.getAttribute("aria-expanded")
        ? btnLink.setAttribute("aria-expanded", "true")
        : btnLink.removeAttribute("aria-expanded");
    });

    // detect mouseout from a dropdown control button - expand-on-mobile only
    flexHeader[0].addEventListener("mouseout", function (event) {
      var btnLink = event.target.closest(".js-f-header__dropdown-control");
      if (!btnLink) return;
      // check layout type
      if (getLayout() == "mobile") return;
      btnLink.removeAttribute("aria-expanded");
    });

    // close dropdown on focusout - expand-on-mobile only
    flexHeader[0].addEventListener("focusin", function (event) {
      var btnLink = event.target.closest(".js-f-header__dropdown-control"),
        dropdown = event.target.closest(".f-header__dropdown");
      if (dropdown) return;
      if (btnLink && btnLink.hasAttribute("aria-expanded")) return;
      // check layout type
      if (getLayout() == "mobile") return;
      var openDropdown = flexHeader[0].querySelector(
        '.js-f-header__dropdown-control[aria-expanded="true"]',
      );
      if (openDropdown) openDropdown.removeAttribute("aria-expanded");
    });

    // listen for resize
    var resizingId = false;
    window.addEventListener("resize", function () {
      clearTimeout(resizingId);
      resizingId = setTimeout(doneResizing, 500);
    });

    function getMenuFirstFocusable() {
      var focusableEle = flexHeader[0]
          .getElementsByClassName("f-header__nav")[0]
          .querySelectorAll(
            '[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable], audio[controls], video[controls], summary',
          ),
        firstFocusable = false;
      for (var i = 0; i < focusableEle.length; i++) {
        if (
          focusableEle[i].offsetWidth ||
          focusableEle[i].offsetHeight ||
          focusableEle[i].getClientRects().length
        ) {
          firstFocusable = focusableEle[i];
          break;
        }
      }

      return firstFocusable;
    }

    function isVisible(element) {
      return (
        element.offsetWidth ||
        element.offsetHeight ||
        element.getClientRects().length
      );
    }

    function doneResizing() {
      if (
        !isVisible(menuTrigger) &&
        flexHeader[0].classList.contains("f-header--expanded")
      ) {
        menuTrigger.click();
      }
      resetFlexHeaderOffset();
    }

    function toggleMenuNavigation(bool) {
      // toggle menu visibility on small devices
      document
        .getElementsByClassName("f-header__nav")[0]
        .classList.toggle("f-header__nav--is-visible", bool);
      flexHeader[0].classList.toggle("f-header--expanded", bool);
      menuTrigger.setAttribute("aria-expanded", bool);
      if (bool)
        firstFocusableElement.focus(); // move focus to first focusable element
      else if (focusMenu) {
        focusMenu.focus();
        focusMenu = false;
      }
    }

    function resetFlexHeaderOffset() {
      // on mobile -> update max height of the flexi header based on its offset value (e.g., if there's a fixed pre-header element)
      document.documentElement.style.setProperty(
        "--f-header-offset",
        flexHeader[0].getBoundingClientRect().top + "px",
      );
    }

    function setAriaButtons() {
      var btnDropdown = flexHeader[0].getElementsByClassName(
        "js-f-header__dropdown-control",
      );
      for (var i = 0; i < btnDropdown.length; i++) {
        var id = "f-header-dropdown-" + i,
          dropdown = btnDropdown[i].nextElementSibling;
        if (dropdown.hasAttribute("id")) {
          id = dropdown.getAttribute("id");
        } else {
          dropdown.setAttribute("id", id);
        }
        btnDropdown[i].setAttribute("aria-controls", id);
      }
    }

    function getLayout() {
      return getComputedStyle(flexHeader[0], ":before")
        .getPropertyValue("content")
        .replace(/\'|"/g, "");
    }
  }
})();
