// File#: _1_preloader

(function () {
  const preloader = document.getElementById("loader");

  if (preloader) {
    setTimeout(() => {
      preloader?.classList.add("done");

      new Promise((resolve) => setTimeout(resolve, 500)).then(() => {
        preloader?.remove();
        document
          .querySelector("[data-animate-headline]")
          ?.classList.add("animate__animated", "animate__fadeInDown");
        document
          .querySelector("[data-animate-subheadline]")
          ?.classList.add("animate__animated", "animate__fadeInUp");
      });
    }, 500);
  } else {
    document
      .querySelector("[data-animate-headline]")
      ?.classList.add("animate__animated", "animate__fadeInDown");
    document
      .querySelector("[data-animate-subheadline]")
      ?.classList.add("animate__animated", "animate__fadeInUp");
  }
})();
