import "./components/_1_preloader";
import "./components/_1_joomla";
import "./components/_1_totop-btn";
import "./components/_1_reveal-effects";
import "./components/_1_anim-menu-btn";
import "./components/_2_flexi-header";
import "./components/_1_countup";
import "./components/_1_accordion";
import "./components/_1_menu";
import "./components/_1_modal-window";
import "./components/_1_swipe-content";
import "./components/_2_menu-bar";
import "./components/_2_slideshow";
import "./components/_3_lightbox";
import "./components/_3_expandable-img-gallery";

import FixedSideNav from "./components/_1_fixed-sidenav";
import Swiper from "swiper";
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";

// init
(() => {
  new Swiper('[data-element="leitprinzipen"]', {
    slidesPerView: 1,
    autoHeight: true,
    modules: [Navigation],
    loop: false,
    grabCursor: false,
    allowTouchMove: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  const fixedSideNav = document.querySelector("[data-fixed-sidenav]");

  if (fixedSideNav) {
    new FixedSideNav(fixedSideNav);
  }

  // leitprinzipen navigation
  const leitprinzipen = document.getElementById("leitprinzipien");
  if (leitprinzipen) {
    const leitprinzipenNav = Array.from(
      leitprinzipen.querySelectorAll("g[id]"),
    );
    const swiper = document.querySelector(
      '[data-element="leitprinzipen"]',
    ).swiper;

    leitprinzipenNav.forEach((nav, index) => {
      nav.addEventListener("click", () => {
        leitprinzipenNav.forEach((nav) => {
          nav.classList.remove("!text-primary-500");
        });

        leitprinzipenNav[index].classList.add("!text-primary-500");

        switch (nav.id) {
          case "unternehmertum":
            swiper.slideTo(0);
            break;
          case "kreativitaet":
            swiper.slideTo(1);
            break;
          case "liberalitaet":
            swiper.slideTo(2);
            break;
          case "dynamik":
            swiper.slideTo(3);
            break;
          case "qualitaet":
            swiper.slideTo(4);
            break;
        }
      });
    });
  }

  new Swiper('[data-element="newsslider"]', {
    slidesPerView: 1,
    autoHeight: false,
    modules: [Navigation],
    loop: true,
    grabCursor: true,
    centeredSlides: true,
    centerInsufficientSlides: true,
    centeredSlidesBounds: true,
    spaceBetween: 10,

    breakpoints: {
      768: {
        slidesPerView: 1.5,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 3.5,
        spaceBetween: 24,
      },
      1600: {
        slidesPerView: 4.25,
        spaceBetween: 32,
      },
    },
    navigation: {
      nextEl: ".swiper__button-next.mod-articlesnews-slider",
      prevEl: ".swiper__button-prev.mod-articlesnews-slider",
    },
  });

  new Swiper('[data-element="karriere"]', {
    slidesPerView: 1,
    autoHeight: false,
    modules: [Navigation],
    loop: false,
    grabCursor: true,
    centeredSlides: true,
    centerInsufficientSlides: true,
    centeredSlidesBounds: true,
    breakpoints: {
      768: {
        slidesPerView: 2.5,
        spaceBetween: 5,
      },
      1024: {
        slidesPerView: 3.5,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper__button-next.mod-articlesnews-slider",
      prevEl: ".swiper__button-prev.mod-articlesnews-slider",
    },
  });

  new Swiper('[data-element="animation"]', {
    slidesPerView: 1.5,
    autoHeight: false,
    modules: [Navigation],
    loop: false,
    grabCursor: true,
    spaceBetween: 0,
    breakpoints: {
      768: {
        slidesPerView: 3.5,
      },
    },
    navigation: {
      nextEl: ".swiper__button-next.mod-animation-slider",
      prevEl: ".swiper__button-prev.mod-animation-slider",
    },
  });

  const sliderTicker = document.querySelectorAll('[data-element="ticker"]');
  if (sliderTicker) {
    sliderTicker.forEach((slider) => {
      new Swiper(slider, {
        slidesPerView: "auto",
        modules: [Navigation, Autoplay, FreeMode],
        autoHeight: false,
        spaceBetween: 0,
        speed: 10000,
        a11y: false,
        loop: true,
        simulateTouch: true,
        grabCursor: true,
        freeMode: true,
        autoplay: {
          delay: 0.5,
          disableOnInteraction: true,
        },
      });
    });
  }

  const sliderGallery = document.querySelectorAll(
    '[data-element="gallery-ticker"]',
  );
  if (sliderGallery) {
    sliderGallery.forEach((slider) => {
      new Swiper(slider, {
        slidesPerView: "auto",
        modules: [Navigation, Autoplay, FreeMode],
        autoHeight: false,
        spaceBetween: 0,
        speed: 5000,
        a11y: false,
        loop: true,
        simulateTouch: true,
        grabCursor: true,
        freeMode: true,
        autoplay: {
          delay: 0.5,
          disableOnInteraction: true,
        },
      });
    });
  }

  // Intersection Observer
  // let options = {
  //   root: null,
  //   rootMargin: "0px",
  //   threshold: 0.1,
  // };

  // let observer = new IntersectionObserver((entries, observer) => {
  //   entries.forEach((entry) => {
  //     if (entry.isIntersecting) {
  //       entry.target.classList.add("animate");
  //     }
  //   });
  // }, options);

  // let modAnimation = document.querySelector(".mod-animation");
  // if (modAnimation) {
  //   observer.observe(modAnimation);
  // }

  // get all iframes with src youtube
  const youtubeIframes = document.querySelectorAll(
    'iframe[src^="https://www.youtube-nocookie.com/embed/"]',
  );

  if (youtubeIframes) {
    let iframesArray = Array.from(youtubeIframes);

    // wrap each iframe in a div
    iframesArray.forEach((iframe) => {
      let div = document.createElement("div");
      div.classList.add("aspect-w-16", "aspect-h-9");
      div.appendChild(iframe.cloneNode(true));
      iframe.parentNode.replaceChild(div, iframe);
    });
  }

  const setPaddingLeft = (source, target) => {
    const content = document.querySelector(source);
    if (!content) return;

    const contentLeftOffset = content.offsetLeft;
    let setMyPaddingLeft = Array.from(document.querySelectorAll(target));

    setMyPaddingLeft.forEach((element) => {
      element.style.setProperty("--my-p-width", contentLeftOffset + "px");
      element.style.setProperty("--my-p-left", contentLeftOffset + "px");
    });
  };

  const setPaddingRight = (source, target) => {
    const content = document.querySelector(source);
    if (!content) return;

    // get right offset of content
    const contentRightOffset =
      content.parentNode.offsetWidth -
      (content.offsetLeft + content.offsetWidth);

    let setMyPaddingRight = Array.from(document.querySelectorAll(target));

    setMyPaddingRight.forEach((element) => {
      element.style.setProperty("--my-p-width", contentRightOffset + "px");
      element.style.setProperty("--my-p-left", contentRightOffset + "px");
    });
  };

  setPaddingLeft(".max-w-contentSize", ".set-my-padding-left");
  setPaddingRight(".max-w-contentSize", ".set-my-padding-right");

  window.addEventListener("resize", () => {
    setPaddingLeft(".max-w-contentSize", ".set-my-padding-left");
    setPaddingRight(".max-w-contentSize", ".set-my-padding-right");
  });

  // Das letzte Element vor dem Footer auswählen
  // let lastElementBeforeFooter = document.querySelector(
  //   ".site-content > :last-child",
  // );

  // // Überprüfen, ob das Element oder eines seiner Kindelemente die Klasse 'bg-gray-100' hat
  // if (lastElementBeforeFooter.querySelector(".bg-gray-100")) {
  //   // Wenn ja, die Klasse 'exist' zum Footer hinzufügen
  //   document.querySelector("footer").classList.add("exist");
  // }

  // window.addEventListener("load", () => {
  //   const lf0 = document.querySelector("#leistungen-form0");
  //   const lf0Image = lf0.querySelector("img");
  //   const lf0ImageHeight = lf0Image.naturalHeight;

  //   let lf0Line = document.createElement("div");
  //   lf0Line.id = "leistungen-form0-line";
  //   lf0Line.classList.add(
  //     "h-[1px]",
  //     "w-[150px]",
  //     "bg-[#45BADE]",
  //     "absolute",
  //     "top-0",
  //     "-right-4",
  //   );

  //   lf0Image.parentNode.appendChild(lf0Line);

  //   // calculate to top position of line of 17% of image height
  //   let lf0LineTop = lf0ImageHeight * 0.17;
  //   lf0Line.style.top = lf0LineTop + "px";
  //   lf0Line.style.left = lf0ImageHeight - 30 + "px";
  // });

  // window.addEventListener("resize", () => {
  //   const lf0 = document.querySelector("#leistungen-form0");
  //   const lf0Image = lf0.querySelector("img");
  //   const lf0ImageHeight = lf0Image.height;
  //   let lf0Line = document.querySelector("#leistungen-form0-line");
  //   let lf0LineTop = lf0ImageHeight * 0.17;
  //   lf0Line.style.top = lf0LineTop + "px";
  //   lf0Line.style.left = lf0ImageHeight - 30 + "px";
  // });
})();
