if (typeof Util === "undefined") {
  var Util = function () {};
}

Util.addClass = function (el, className) {
  var classList = className.split(" ");
  el.classList.add(classList[0]);
  if (classList.length > 1) Util.addClass(el, classList.slice(1).join(" "));
};

Util.removeClass = function (el, className) {
  var classList = className.split(" ");
  el.classList.remove(classList[0]);
  if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(" "));
};

Util.getIndexInArray = function (array, el) {
  return Array.prototype.indexOf.call(array, el);
};

Util.osHasReducedMotion = function () {
  if (!window.matchMedia) return false;
  var matchMediaObj = window.matchMedia("(prefers-reduced-motion: reduce)");
  if (matchMediaObj) return matchMediaObj.matches;
  return false;
};

// File#: _1_reveal-effects
// Usage: codyhouse.co/license
(function () {
  var fxElements = document.getElementsByClassName("reveal-fx");
  var intersectionObserverSupported =
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype;
  if (fxElements.length > 0) {
    // deactivate effect if Reduced Motion is enabled
    if (Util.osHasReducedMotion() || !intersectionObserverSupported) {
      fxRemoveClasses();
      return;
    }
    //on small devices, do not animate elements -> reveal all
    if (fxDisabled(fxElements[0])) {
      fxRevealAll();
      return;
    }

    var fxRevealDelta = 25; // amount (in pixel) the element needs to enter the viewport to be revealed - if not custom value (data-reveal-fx-delta)

    var viewportHeight = window.innerHeight,
      fxChecking = false,
      fxRevealedItems = [],
      fxElementDelays = fxGetDelays(), //elements animation delay
      fxElementDeltas = fxGetDeltas(); // amount (in px) the element needs enter the viewport to be revealed (default value is fxRevealDelta)

    // add event listeners
    window.addEventListener("load", fxReveal);
    window.addEventListener("resize", fxResize);
    window.addEventListener("restartAll", fxRestart);

    // observe reveal elements
    var observer = [];
    initObserver();

    function initObserver() {
      for (var i = 0; i < fxElements.length; i++) {
        observer[i] = new IntersectionObserver(
          function (entries, observer) {
            if (entries[0].isIntersecting) {
              fxRevealItemObserver(entries[0].target);
              observer.unobserve(entries[0].target);
            }
          },
          { rootMargin: "0px 0px -" + fxElementDeltas[i] + "px 0px" },
        );

        observer[i].observe(fxElements[i]);
      }
    }

    function fxRevealAll() {
      // reveal all elements - small devices
      for (var i = 0; i < fxElements.length; i++) {
        Util.addClass(fxElements[i], "reveal-fx--is-visible");
      }
    }

    function fxResize() {
      // on resize - check new window height and reveal visible elements
      if (fxChecking) return;
      fxChecking = true;
      !window.requestAnimationFrame
        ? setTimeout(function () {
            fxReset();
          }, 250)
        : window.requestAnimationFrame(fxReset);
    }

    function fxReset() {
      viewportHeight = window.innerHeight;
      fxReveal();
    }

    function fxReveal() {
      // reveal visible elements
      for (var i = 0; i < fxElements.length; i++) {
        (function (i) {
          if (fxRevealedItems.indexOf(i) != -1) return; //element has already been revelead
          if (fxElementIsVisible(fxElements[i], i)) {
            fxRevealItem(i);
            fxRevealedItems.push(i);
          }
        })(i);
      }
      fxResetEvents();
      fxChecking = false;
    }

    function fxRevealItem(index) {
      if (fxElementDelays[index] && fxElementDelays[index] != 0) {
        // wait before revealing element if a delay was added
        setTimeout(function () {
          Util.addClass(fxElements[index], "reveal-fx--is-visible");
        }, fxElementDelays[index]);
      } else {
        Util.addClass(fxElements[index], "reveal-fx--is-visible");
      }
    }

    function fxRevealItemObserver(item) {
      var index = Util.getIndexInArray(fxElements, item);
      if (fxRevealedItems.indexOf(index) != -1) return; //element has already been revelead
      fxRevealItem(index);
      fxRevealedItems.push(index);
      fxResetEvents();
      fxChecking = false;
    }

    function fxGetDelays() {
      // get anmation delays
      var delays = [];
      for (var i = 0; i < fxElements.length; i++) {
        delays.push(
          fxElements[i].getAttribute("data-reveal-fx-delay")
            ? parseInt(fxElements[i].getAttribute("data-reveal-fx-delay"))
            : 50,
        );
      }
      return delays;
    }

    function fxGetDeltas() {
      // get reveal delta
      var deltas = [];
      for (var i = 0; i < fxElements.length; i++) {
        deltas.push(
          fxElements[i].getAttribute("data-reveal-fx-delta")
            ? parseInt(fxElements[i].getAttribute("data-reveal-fx-delta"))
            : fxRevealDelta,
        );
      }
      return deltas;
    }

    function fxDisabled(element) {
      // check if elements need to be animated - no animation on small devices
      return !(
        window
          .getComputedStyle(element, "::before")
          .getPropertyValue("content")
          .replace(/'|"/g, "") == "reveal-fx"
      );
    }

    function fxElementIsVisible(element, i) {
      // element is inside viewport
      return (
        fxGetElementPosition(element) <= viewportHeight - fxElementDeltas[i]
      );
    }

    function fxGetElementPosition(element) {
      // get top position of element
      return element.getBoundingClientRect().top;
    }

    function fxResetEvents() {
      if (fxElements.length > fxRevealedItems.length) return;
      // remove event listeners if all elements have been revealed
      window.removeEventListener("load", fxReveal);
      window.removeEventListener("resize", fxResize);
    }

    function fxRemoveClasses() {
      // Reduced Motion on or Intersection Observer not supported
      while (fxElements[0]) {
        // remove all classes starting with 'reveal-fx--'
        var classes = fxElements[0]
          .getAttribute("class")
          .split(" ")
          .filter(function (c) {
            return c.lastIndexOf("reveal-fx--", 0) !== 0;
          });
        fxElements[0].setAttribute("class", classes.join(" ").trim());
        Util.removeClass(fxElements[0], "reveal-fx");
      }
    }

    function fxRestart() {
      // restart the reveal effect -> hide all elements and re-init the observer
      if (
        Util.osHasReducedMotion() ||
        !intersectionObserverSupported ||
        fxDisabled(fxElements[0])
      ) {
        return;
      }
      // check if we need to add the event listensers back
      if (fxElements.length <= fxRevealedItems.length) {
        window.addEventListener("load", fxReveal);
        window.addEventListener("resize", fxResize);
      }
      // remove observer and reset the observer array
      for (var i = 0; i < observer.length; i++) {
        if (observer[i]) observer[i].disconnect();
      }
      observer = [];
      // remove visible class
      for (var i = 0; i < fxElements.length; i++) {
        Util.removeClass(fxElements[i], "reveal-fx--is-visible");
      }
      // reset fxRevealedItems array
      fxRevealedItems = [];
      // restart observer
      initObserver();
    }
  }
})();
